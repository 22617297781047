.nav-button {
  margin-top: 0px !important;
  color: #000000 !important;
  font-weight: bold !important;
  text-decoration: none;
  border: 0;
}

@media (min-width: 768px) {
  .nav-button {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}
