/**
*
* Author: Anibe Sam
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Patrick+Hand&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@100&display=swap");

body {
  font-family: "Raleway", sans-serif !important;
  color: #f2f2f2;
}

a {
  color: #2487ce;
  text-decoration: none;
}

a:hover {
  color: #469fdf;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #2487ce;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #3e9bdd;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #2487ce;
  border-top-color: #fff;
  border-bottom-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  background: #000;
  z-index: 997;
  padding: 15px 0;
  border-bottom: 1px solid #e6f2fb;
}

#header.header-scrolled {
  border-color: #fff;
  box-shadow: 0px 2px 15px rgba(18, 66, 101, 0.08);
}

#header .logo {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 300;
  letter-spacing: 0.5px;
  font-family: "Raleway", sans-serif;
}

#header .logo a {
  color: #16507b;
}

#header .logo img {
  max-height: 40px;
}

@media (max-width: 992px) {
  #header .logo {
    font-size: 28px;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 14px;
  color: #ffffff !important;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #eca239 !important;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  background: #eca239;
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 4px;
  color: #000000 !important;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #000000 !important;
  background: #eca239;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  text-transform: none;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #2487ce;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
* Mobile Navigation
*/
.mobile-nav-toggle {
  color: #ffffff !important;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(10, 38, 58, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #124265;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #2487ce;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #2487ce;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  position: relative;
  background: url("../public/img/hero-bg.png") top center;
  background-size: cover;
}

#hero:before {
  content: "";
  background: url("../public/img/hero-bg.png") top center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .container {
  padding-top: 80px;
}

#hero h1 {
  margin: 0;
  font-size: 46px;
  font-weight: 700;
  line-height: 72px;
  color: #124265;
  font-family: "Raleway", sans-serif;
}

#hero h2 {
  color: #5e5e5e;
  margin: 10px 0 0 0;
  font-size: 22px;
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  display: inline-block;
  padding: 14px 50px;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 30px;
  color: #000000;
  background: #eca239;
}

#hero .btn-get-started:hover {
  background: #eca239;
}

#hero .icon-boxes {
  margin-top: 100px;
}

#hero .icon-box {
  padding: 50px 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(18, 66, 101, 0.08);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}

#hero .icon-box .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

#hero .icon-box .title a {
  color: #124265;
  transition: 0.3s;
}

#hero .icon-box .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

#hero .icon-box .icon {
  margin-bottom: 20px;
  padding-top: 10px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  font-size: 36px;
  line-height: 1;
  color: #2487ce;
}

#hero .icon-box:hover {
  transform: scale(1.08);
}

#hero .icon-box:hover .title a {
  color: #2487ce;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-height: 800px) {
  #hero {
    height: auto;
  }
}

@media (max-width: 992px) {
  #hero {
    height: auto;
  }

  #hero h1 {
    font-size: 28px;
    line-height: 36px !important;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 80px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f8fbfe;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #124265;
}

.section-title p {
  margin-bottom: 0;
  font-size: 14px;
  color: #919191;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/

.about {
  background-color: #eca239;
  color: #000000 !important;
}
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-left: 28px;
  position: relative;
}

.about .content ul li + li {
  margin-top: 10px;
}

.about .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #2487ce;
  line-height: 1;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .content .btn-learn-more {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 5px;
  transition: 0.3s;
  line-height: 1;
  color: #2487ce;
  animation-delay: 0.8s;
  margin-top: 6px;
  border: 2px solid #2487ce;
}

.about .content .btn-learn-more:hover {
  background: #2487ce;
  color: #fff;
  text-decoration: none;
}

.about .play-btn {
  width: 94px;
  height: 94px;

  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.about .play-btn:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-btn 2s;
  animation-iteration-count: infinite;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(13, 66, 255, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.about .play-btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.about .play-btn:hover:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  animation: none;
  border-radius: 0;
}

.about .play-btn:hover:after {
  transform: scale(20);
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding: 60px 0;
}

.counts .count-box {
  width: 100%;
  text-align: center;
}

.counts .count-box span {
  font-size: 48px;
  line-height: 48px;
  display: block;
  font-weight: 700;
  color: #124265;
  margin-left: 80px;
  margin: auto;
}

.counts .count-box p {
  padding: 8px 0 0 0;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #1e6ca6;
}

/*--------------------------------------------------------------
# About Video
--------------------------------------------------------------*/
.about-video .content {
  font-size: 15px;
}

.about-video .content h3 {
  font-weight: 700;
  font-size: 24px;
  color: #124265;
}

.about-video .content ul {
  list-style: none;
  padding: 0;
}

.about-video .content ul li {
  padding-bottom: 10px;
  padding-left: 28px;
  position: relative;
}

.about-video .content ul i {
  font-size: 24px;
  color: #2487ce;
  position: absolute;
  left: 0;
  top: -2px;
}

.about-video .content p:last-child {
  margin-bottom: 0;
}

.about-video .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(#2487ce 50%, rgba(36, 135, 206, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.about-video .play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.about-video .play-btn::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-btn 2s;

  animation-iteration-count: infinite;

  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(36, 135, 206, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.about-video .play-btn:hover::after {
  border-left: 15px solid #2487ce;
  transform: scale(20);
}

.about-video .play-btn:hover::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  animation: none;
  border-radius: 0;
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  padding: 15px 0;
  text-align: center;
  background-color: #bbbdc0;
}

.clients img {
  max-width: 65%;
  display: inline-block;
  padding: 15px 0;
  filter: brightness(0%);
}

.clients img:hover {
  filter: none;
}

@media (max-width: 768px) {
  .clients img {
    max-width: 40% !important;
  }
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  text-align: center;
  min-height: 320px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #c9e3f5;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 15px 15px 15px;
  padding: 20px;
  background: #f3f9fd;
  position: relative;
  margin-bottom: 35px;
  border-radius: 6px;
}

.testimonials .testimonial-item p::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 20px solid #f3f9fd;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  position: absolute;
  bottom: -20px;
  left: calc(50% - 20px);
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #2487ce;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #2487ce;
}
/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  margin-bottom: 20px;
  padding: 30px;
  border-radius: 6px;
  background: #bbbdc0;
  transition: 0.3s;
  position: relative;
}

.services .icon-box:hover {
  background: #2b2b2b;
}

.services .icon-box i {
  float: left;
  color: #e03a3c;
  font-size: 40px;
  line-height: 0;
}

.services .icon-box h4 {
  margin-left: 70px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .icon-box h4 a {
  color: #fff;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  text-decoration: underline;
}

.services .icon-box .icon-box:hover h4 a {
  color: #e03a3c;
}

.services .icon-box p {
  margin-left: 70px;
  line-height: 24px;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
  background: #2487ce;
  background-size: cover;
  padding: 60px 0;
}

.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.cta p {
  color: #fff;
}

.cta .cta-btn {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 35px;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.cta .cta-btn:hover {
  background: #fff;
  color: #2487ce;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 35px auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
  padding: 2px 15px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px 8px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #2487ce;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(36, 135, 206, 0.6);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio .portfolio-wrap .portfolio-info::before {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  top: 35px;
  left: 35px;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info::after {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 35px;
  right: 35px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: rgba(255, 255, 255, 0.6);
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #fff;
}

.portfolio .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info::before {
  top: 15px;
  left: 15px;
}

.portfolio .portfolio-wrap:hover .portfolio-info::after {
  bottom: 15px;
  right: 15px;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #2487ce;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #2487ce;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(18, 66, 101, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  text-align: center;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(18, 66, 101, 0.08);
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40px;
  opacity: 0;
  transition: ease-in-out 0.3s;
  text-align: center;
  background: rgba(255, 255, 255, 0.85);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.team .member .social a {
  transition: color 0.3s;
  color: #124265;
  margin: 0 10px;
  display: inline-block;
}

.team .member .social a:hover {
  color: #2487ce;
}

.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
  line-height: 0;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #124265;
}

.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #aaaaaa;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.team .member:hover .social {
  opacity: 1;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
  padding: 40px 20px;
  text-align: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  background: #fefefe;
  box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.1);
}

.pricing .box h3 {
  font-weight: 400;
  padding: 15px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  color: #124265;
}

.pricing .box h4 {
  font-size: 42px;
  color: #2487ce;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  margin-bottom: 20px;
}

.pricing .box h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing .box h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.pricing .box ul {
  padding: 0;
  list-style: none;
  color: #124265;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.pricing .box ul li {
  padding-bottom: 16px;
}

.pricing .box ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing .box .btn-wrap {
  padding: 15px;
  text-align: center;
}

.pricing .box .btn-buy {
  display: inline-block;
  padding: 10px 40px;
  border-radius: 4px;
  color: #2487ce;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  transition: 0.3s;
  border: 2px solid #2487ce;
}

.pricing .box .btn-buy:hover {
  background: #2487ce;
  color: #fff;
}

.pricing .featured {
  background: #2487ce;
}

.pricing .featured h3,
.pricing .featured h4,
.pricing .featured h4 span,
.pricing .featured ul,
.pricing .featured ul .na {
  color: #fff;
}

.pricing .featured .btn-wrap {
  padding: 15px;
  text-align: center;
}

.pricing .featured .btn-buy {
  color: #fff;
  border: 2px solid #fff;
}

.pricing .featured .btn-buy:hover {
  background: #fff;
  color: #2487ce;
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .faq-list {
  padding: 0 100px;
}

.faq-bg {
  background-color: #ffffff;
}

.faq .faq-list ul {
  padding: 0;
  list-style: none;
}

.faq .faq-list li + li {
  margin-top: 15px;
}

.faq .faq-list li {
  padding: 20px;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
}

.faq .faq-list a {
  display: block;
  position: relative;
  font-family: "realway", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
  color: #000000;
}

.faq .faq-list .icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 10px;
  color: #eca239;
}

.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list a.collapsed {
  color: #000000;
}

.faq .faq-list a.collapsed:hover {
  color: #eca239;
}

.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1200px) {
  .faq .faq-list {
    padding: 0;
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  width: 100%;
  background: #fff;
}

.contact .info i {
  font-size: 20px;
  color: #2487ce;
  float: left;
  width: 44px;
  height: 44px;
  background: #e3f0fa;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #124265;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #217bbc;
}

.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #2487ce;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #2487ce;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
  background: #2487ce;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #3194db;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #f8fbfe;
  min-height: 40px;
  margin-top: 72px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 58px;
  }
}

.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 300;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #1a5e90;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  color: #000000;
  font-size: 14px;
  background: #eca239;
  box-shadow: 0px 2px 15px rgba(18, 66, 101, 0.08);
}

#footer .footer-top {
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 26px;
  margin: 0 0 10px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 300;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  color: #777777;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #124265;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #2487ce;
  font-size: 18px;
  line-height: 1;
  align-items: center;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #2487ce;
}

#footer .footer-newsletter {
  font-size: 15px;
}

#footer .footer-newsletter h4 {
  font-size: 16px;
  font-weight: bold;
  color: #444444;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 5px;
  text-align: left;
  border: 1px solid #cde5f6;
}

#footer .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: -1px;
  right: -2px;
  bottom: -1px;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px 2px 20px;
  background: #2487ce;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 5px 5px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type="submit"]:hover {
  background: #3194db;
}

#footer .credits {
  padding-top: 5px;
  font-size: 13px;
  color: #444444;
}

#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #2487ce;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 5px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links a:hover {
  background: #000000;
  color: #fff;
  text-decoration: none;
}

.btn-about {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 30px;
  color: #ffffff !important;
  background: #000000 !important;
}

.btn-about :hover {
  color: #ffffff !important;
  background: #000000 !important;
}

.btn-orange {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 30px;
  color: #000000 !important;
  background: #eca239;
}

.btn-orange :hover {
  color: #000000 !important;
  background: #eca239;
}

.btn-black {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 30px;
  color: #ffffff !important;
  background: #000000;
}

.btn-image-animate {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 30px;
  color: #000000;
  background: #eca239;
}

#gant {
  width: 100%;
  position: relative;
  background: url("../public/img/bg/Secti.png") top center;
  background-size: cover;
  position: relative;
}

#gant:before {
  content: "";
  background: url("../public/img/bg/Secti.png") top center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

section.footer {
  background-color: #eca239;
  padding: 40px 0 20px 0px;
  color: #000000;
  border-top: 2px solid #eca239;
  font-size: 18px;
}

section.footer a,
section.footer p,
section.footer div {
  color: #000000;
  font-size: 14px;
}

section.footer a:hover {
  color: #ffffff;
}

.social-icons {
  display: flex;
  justify-content: center;
}

.icon-circle {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.border-anibe {
  border: 0.6px solid #eca239 !important;
}

/* slider Control Button */

.sliderCon {
  position: relative;
}

.sliderArrow {
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.arrow1 {
  border-radius: 4px 4px 0 0;
}

.arrow2 {
  border-radius: 0 0 4px 4px;
}

.sliderArrow button {
  border: none;
  background-color: black;
  color: white;
}

@media (max-width: 768px) {
  .sliderArrow {
    right: 40px;
  }
}

/* timeline background*/

.cardCon {
  position: relative;
}

.curvy-line {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0; /* Move the SVG behind the content */
}

.card1,
.card2,
.card3,
.card4,
.card5,
.card6,
.card7,
.card8,
.card9,
.card10,
.card11 {
  position: relative;
}

.dot1 {
  position: absolute;
  background-color: #eca239;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: 36.7%;
  left: 95.6%;
}

.dot2 {
  position: absolute;
  background-color: #eca239;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: 39%;
  left: 2%;
}

.dot2a {
  position: absolute;
  background-color: #eca239;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: 75%;
  left: 2%;
}

.dot3 {
  position: absolute;
  background-color: #eca239;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: -2%;
  right: 9%;
}

.dot3a {
  position: absolute;
  background-color: #eca239;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: 44%;
  left: 95.6%;
}

.dot4 {
  position: absolute;
  background-color: #eca239;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: 42%;
  left: 2%;
}

.dot4a {
  position: absolute;
  background-color: #eca239;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  bottom: 7%;
  left: 2%;
}

.dot5 {
  position: absolute;
  background-color: #eca239;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: -2%;
  right: 6%;
}

.dot5a {
  position: absolute;
  background-color: #eca239;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: 40%;
  left: 95.6%;
}

.dot6 {
  position: absolute;
  background-color: #eca239;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: 51%;
  left: 2%;
}

.dot6a {
  position: absolute;
  background-color: #eca239;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  bottom: 3.7%;
  left: 2%;
}

.dot7 {
  position: absolute;
  background-color: #eca239;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: -2%;
  right: 9%;
}

.dot7a {
  position: absolute;
  background-color: #eca239;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: 34.5%;
  left: 95.6%;
}

.dot8 {
  position: absolute;
  background-color: #eca239;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: 38%;
  left: 2%;
}

.dot8a {
  position: absolute;
  background-color: #eca239;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  bottom: 5%;
  left: 2%;
}

.dot9 {
  position: absolute;
  background-color: #eca239;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: -2%;
  right: 10%;
}

.dot9a {
  position: absolute;
  background-color: #eca239;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: 51%;
  left: 95.6%;
}

.dot10 {
  position: absolute;
  background-color: #eca239;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: 49%;
  left: 2%;
}

.dot10a {
  position: absolute;
  background-color: #eca239;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  bottom: -0.5%;
  left: 65%;
}

.dot11 {
  position: absolute;
  background-color: #eca239;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: 37%;
  right: 2%;
}

@media (max-width: 1024px) {
  .dot1,
  .dot2a,
  .dot3a,
  .dot4a,
  .dot5a,
  .dot6a,
  .dot7a,
  .dot8a,
  .dot9a,
  .dot10a {
    top: 96.7%;
    left: 50%;
  }
  .dot11,
  .dot2,
  .dot3,
  .dot4,
  .dot5,
  .dot6,
  .dot7,
  .dot8,
  .dot9,
  .dot10 {
    top: -2.7%;
    left: 50%;
  }
}

@media (max-width: 768px) {
  .dot1,
  .dot2a,
  .dot3a,
  .dot4a,
  .dot5a,
  .dot6a,
  .dot7a,
  .dot8a,
  .dot9a,
  .dot10a {
    display: none;
  }
  .dot11,
  .dot2,
  .dot3,
  .dot4,
  .dot5,
  .dot6,
  .dot7,
  .dot8,
  .dot9,
  .dot10 {
    display: none;
  }
  .curvy-line {
    display: none;
  }
}
/* .background-svg {
  background-image: url('../public/img/bg/linker.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1000.961px ;
  animation: moveDiagonally 55s infinite alternate; 
  baseline-shift: baseline;
  flex-shrink: 0;
} */

.why-bg {
  background: url("../public/img/bg/Section.png") center no-repeat;

  background-color: #ebecee !important;

  background-size: cover;
}

.beneicon-container {
  width: 75px;
  height: 75px;
  background-color: #eca239;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
}

.beneicon {
  font-size: 24px;
  color: white;
}

.accordion-button.collapsed::after {
  background: url("../public/plus-circle.svg") no-repeat;
}

.accordion-button:not(.collapsed)::after {
  background: url("../public/dash-circle.svg") no-repeat;
}

.panel,
.panel-group .panel-heading + .panel-collapse > .panel-body {
  border: none;
}

accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-item:first-of-type {
  border-top-left-radius: none;
  border-top-right-radius: none;
}
.accordion-item {
  color: var(--bs-accordion-color);
  background-color: none;
  border: none;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: none !important;
  border-top-right-radius: none !important;
}

.accordion-button:not(.collapsed) {
  color: #eca239 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.slick-track {
  display: flex;
  gap: 1rem;
}

.bold-text {
  font-weight: 700;
}
